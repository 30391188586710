import React, { Component } from 'react';
/*
import Dot from './dot';
import { modeNames } from '../constants';
import { midiNames } from '../constants';
*/
import { Score } from 'react-vexflow'

class Notation extends Component {

  render() {
		return (
	    <Score
	      staves={[
	        ['g3', 'd4', 'e4', 'd4'],
	        ['a4', 'd4', 'e4', 'd4'],
	        ['a4', 'a4', 'b4', 'a4'],
	        ['d4', 'e4', ['g3', 2]],
	      ]}
	    />
	  )
  }
}

export default Notation;
