import React, { Component } from 'react'
import PropTypes from 'prop-types'
import logo from './fretlogic-logo.png'
import Fretboard from './components/fretboard'
import Dot from './components/dot'
import Notation from './components/vex'
import { BrowserRouter as Router, Route, Redirect, Link } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";
import './App.css'
import { modeNames, midiNames, qz, instrumentNames } from './constants';
import Soundfont from 'soundfont-player';
import { Helmet } from 'react-helmet';
import Switch from 'react-switch';

var ALL_SCALEMODELS = {
  'dur': [1,0,2,0,3,4,0,5,0,6,0,7],
  'mm': [1,0,2,3,0,4,0,5,0,6,0,7],
  'hm': [1,0,2,3,0,4,0,5,6,0,0,7],
  'hd': [1,0,2,0,3,4,0,5,6,0,0,7],
  'penta':[1,0,0,0,4]
}

const ARRAYKEYS = ['showStep','showString'];
const INTKEYS = ['fret_from','fret_range','qzr','rootIndex','scaleStep']
const BOOLKEYS = ['slideMode','creativeMode']

//const COLORS = ['#fcb76f', '#e88666', '#c05166', '#8f3066', '#541d61']
const COLORS = ['#66545e','#a39193','#aa6f73','#eea990','#d1be99']


const styles = theme => ({
  root: {
    maxWidth: 700,
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
    margin: "auto"
  },
  table: {
    minWidth: 700
  },
  col: {
    width: '150'
  },
  innerrow: {
    marginTop: '5px',
    width: '100%',
    marginLeft: '0px',
    padding: '0px'
  },
  inlineSelect: {
    display: 'inline'
  }
});

function App() {

  const title = "Fretlogic: Griffbrettvisualisierung";

  return (
    <Router>
    <div>
      <Route exact path="/" component={withStyles(styles)(FretboardLayer)} />
      <Route path="/impressum" component={Impressum} />
    </div>
    <Helmet>
      <title>{title}</title>
      <meta property="og:image" content="http://www.fretlogic.de/static/media/fretlogic-logo.55855e0e.png" />
    </Helmet>
    </Router>
  )
}

function Impressum() {
  return (
    <div className="App" style={{backgroundColor: '#282c34'}}>
      <div className="row">
        <div
          className="col"
          style={{textAlign:'center',
            height:'80px',
            paddingTop:'1em',
          color:'white'}}>
          <h1><Link to="/">Fretlogic</Link></h1>
        </div>
      </div>
      <div className="row">
        <div className="col" style={{color:'white',textAlign:'center'}}>

          <div class='impressum'>
            <h3>Impressum</h3>
            <p>Angaben gemäß § 5 TMG</p>
            <p>Christoph Pingel <br/>
            Zum Brandbühl 6<br/>
            88662 Überlingen <br/>
            </p>
            <p> <strong>Vertreten durch: </strong><br/>
            Christoph Pingel<br/>
            </p>
            <p><strong>Kontakt:</strong> <br/>
            Telefon: 0151-16585662<br/>
          E-Mail: <a href='mailto:pingelsan@gmail.com'>pingelsan@gmail.com</a><br/>
            </p>
            <p><strong>Umsatzsteuer-ID: </strong> <br/>
          Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz: DE316961500<br/><br/>
          </p><p><strong>Haftungsausschluss: </strong><br/><br/><strong>Haftung für Links</strong><br/><br/>
      </p>
      <p style={{width:'50%',margin:'auto', paddingBottom:'5em'}}>
          Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>

           </div>



        </div>
      </div>
    </div>
  )
}

class FretboardLayer extends Component {

  constructor(props) {
		super(props);
		this.state = {
      scale_model: [1,0,2,0,3,4,0,5,0,6,0,7],
      scale_model_current_key: 'dur',
      scaleStep: 0,
			showStep: [true, true, true, true, true, true, true],
      showString: [true, true, true, true, true, true],
      creativeMainChord: [0,0,0,0,0,0],
      creativeChords: [],
      rootIndex: 12,
      modeRoot: 12,
      fret_from: 0,
      fret_to: 14,
      fret_range: 12,
      player: null,
      qzr: 1,
      instrument: 'acoustic_guitar_nylon',
      beschriftung: 'Stufe',
      slideMode: false,
      creativMode: false,
      creativeChordColorIndex: {}
		}
    this.toggleShowStep = this.toggleShowStep.bind(this);
    this.toggleShowString = this.toggleShowString.bind(this);
    this.chooseScaleModel = this.chooseScaleModel.bind(this);
    this.chooseRoot = this.chooseRoot.bind(this);
    this.chooseStepByInt = this.chooseStepByInt.bind(this);
    this.computeFretTo = this.computeFretTo.bind(this);
    this.initializePlayer = this.initializePlayer.bind(this);
    this.chooseInstrument = this.chooseInstrument.bind(this);
    this.setStateAndStorage = this.setStateAndStorage.bind(this);
    this.toggleSlideMode = this.toggleSlideMode.bind(this);
    this.toggleCreativeMode = this.toggleCreativeMode.bind(this);
    this.setCreativePitch = this.setCreativePitch.bind(this);
    this.setCreativeChordScale = this.setCreativeChordScale.bind(this);
    this.getCreativeChordColor = this.getCreativeChordColor.bind(this);
	}

  initializePlayer(p) {
    this.setState({player:p});
  }

  setStateAndStorage(o) {
    this.setState(o);
    Object.keys(o).map((k) => {
      localStorage.setItem(k, o[k]);
    })
  }

  readLocalStorage() {
    console.log("readingLocalStorage", localStorage);
    console.log(Object.keys(localStorage));
    const storageKeys = Object.keys(localStorage);
    const stateMap = {}
    storageKeys.map((k) => {
      var val = localStorage.getItem(k);
      //console.log("k:", k, ", val:", val);
      if (val !== null) {
        if (ARRAYKEYS.indexOf(k) > -1) {
          val = val.split(',').map((v) => eval(v));
        }
        if (INTKEYS.indexOf(k) > -1) {
          console.log("parsingInt:", val);
          val = parseInt(val, 10);
        }
        if (BOOLKEYS.indexOf(k) > -1) {
          val = eval(val);
        }
        stateMap[k] = val;
      }
    })
    console.log(stateMap);
    return stateMap;
  }



  componentDidMount() {
    console.log("componentDidMount");
    this.setState(this.readLocalStorage());
    var acc = window.AudioContext || window.webkitAudioContext;
    const ac = new acc();
    var instrument = localStorage.getItem("instrument") || this.state.instrument;

    Soundfont.instrument(ac, instrument, { soundfont: 'MusyngKite' }).then(marimba => this.setState({player:marimba}));
  }

  toggleShowStep(i) {
    const a = this.state.showStep;
    a[i] = !a[i];

    this.setStateAndStorage({showStep: a});

    // Hier sind wir eigentlich fertig.
    // Aber nicht, wenn wir im Voicing-Mode sind.


    const creativeChord = this.state.creativeMainChord;

    console.log(this.state.scale_model_current_key)

    // Abfolge der Steps innerhalb der Oktave z.B. [3,4,0,5,0,6,0,7,1,0,2,0]
    var scaleModel = this.computeFinalScalemodel();

    // Verlängern zum Zugriff
    while (scaleModel.length < 48) {
      scaleModel = scaleModel.concat(scaleModel);
    }

    // auf den gewählten Grundton anpassen
    scaleModel = scaleModel.slice(this.state.rootIndex);

    //
    var j = i+1;

    // Hier kommen die gefilterten Noten des Basisakkords rein.
    // Noten, deren "Step" in der aktuellen Diatonik nicht vorkommt,
    // werden gelöscht
    const newCreativeChord = [];

    for (var n of creativeChord) {

      var localPitch;
      if (n === 0) {
        localPitch = 0;
        newCreativeChord.push(0);
      } else {
        if (n % 12 === 0) {
          localPitch = 12; // Sonderbehandlung root, darf nicht 0 sein
        } else {
          localPitch = n % 12; // Modulo zum leichteren auffinden
        }
        var step = scaleModel[localPitch];
        if (a[i] === false && step === j) { // Step wurde abgewählt
          newCreativeChord.push(0);
        } else {
          newCreativeChord.push(n);
        }
      }
      console.log("localPitch:", localPitch, " - step:", scaleModel[localPitch]);
    }

    // TODO: beim Abwählen werden die Akkorde nicht richtig gerendert
    // Und zwar dann (Hinweis!), wenn der abgewählte Step im Main Chord
    // enthalten ist! -> Vermutlich müssen die abgewählten
    // Steps aus dem Main Chord entfernt werden!
    this.setCreativeChordScale(newCreativeChord);
  }

  toggleShowString(i) {
    const a = this.state.showString;
    a[i] = !a[i];
    this.setStateAndStorage({showString: a});
  }

  chooseScaleModel(e) {
    // Hier wird die zugrundeliegende 7-Ton-Tonleiter ausgewählt der richtige Modus
    // berechnet
    const k = e.target.value;
    console.log("scalemodel:", k);
    this.setStateAndStorage({scale_model_current_key: k});
  }

  computeCreativeChordScale() {
    const tuning = [64, 59, 55, 50, 45, 40]
    var scaleModel = this.computeFinalScalemodel();
    while (scaleModel.length < 48) {
      scaleModel = scaleModel.concat(scaleModel);
    } // Liste 'extrudieren', damit sie über die Oktaven reicht
    const notes = [0,1,2,3,4,5].map((j) =>
      [	...Array(24).keys()].map((i) => {
        //console.log(j+1, i);
        const scale_offset = tuning[j] % 12;
        /*
        if (i === 0 && j === 0) {
					console.log("app -> scaleModel:", scaleModel);
					console.log("app -> scale_offset:", scale_offset);
					console.log("app -> rootIndex:", this.state.rootIndex);
				}
        */

        var compare_SCALE = scaleModel.slice(scale_offset).slice(this.state.rootIndex);
        //compare_SCALE = [0,0].concat(compare_SCALE);
        //console.log(compare_SCALE)

        /*
        if (i === 0 && j === 0) {
          console.log("app -> compare_SCALE:", compare_SCALE);
        }
        */

        //const activeFret = (i >= this.props.fretFrom) && (i <= this.props.fretTo);
        const step = compare_SCALE[i];

        if (step !== 0 && this.state.showString[j] && this.state.showStep[step - 1] === true ) {
          return {
            pitch: tuning[j]+i,
            string: j,
            step: compare_SCALE[i]
          }
          /*
          return <Dot
                    slideMode={this.props.slideMode}
                    player={this.props.soundPlayer}
                    active={activeFret}
                    x={this.dotpos(i)}
                    y={15 + (j * 30)}
                    string={j}
                    pitch={tuning[j] + i}
                    qzr={this.props.qzr}
                    key={tuning[j]+i}
                    createPitchFunction={this.props.createPitchFunction}
                    creativeMainChord={this.props.creativeMainChord}
                    beschriftung={this.props.beschriftung}
                    creativeMode={this.props.creativeMode}
                    step={compare_SCALE[i]}/>
            */
        } else {
          return null
        }
      }
      )
    )
    //console.log('notes:',notes);

    const finalNotes = []
    for (var s of notes) {
      const n = [];
      for (var o of s) {
        if (o !== null) {
          n.push(o);
        }
      }
      finalNotes.push(n);
    }

    return finalNotes;

  }

  computeFinalScalemodel() {

    // Mit welcher Skala k wollen wir arbeiten?
    const k = this.state.scale_model_current_key;

    // richtige Skala rauspicken
    var scaleModel = ALL_SCALEMODELS[k];

    for (var i = 0; i < this.state.scaleStep; i++) {
      scaleModel = this.nextStepScalemodel(scaleModel);

    }
    return scaleModel;
  }

  computeTonicScalemodel() {
    const k = this.state.scale_model_current_key;
    return ALL_SCALEMODELS[k];
  }

  chooseStep(e) {
    //console.log(e.target.value);
    this.setStateAndStorage({scaleStep: e.target.value});
  }

  chooseStepByInt(i) {
    this.setStateAndStorage({scaleStep: i});
  }

  chooseFretFrom(e) {
    this.setStateAndStorage({fret_from: e.target.value})
  }

  chooseFretRange(e) {
    this.setStateAndStorage({fret_range: e.target.value})
  }

  chooseInstrument(e) {
    console.log(e.target.value);
    console.log(localStorage);
    this.setStateAndStorage({instrument: e.target.value});
    var acc = window.AudioContext || window.webkitAudioContext;
    const ac = new acc();
    Soundfont.instrument(ac, e.target.value, { soundfont: 'MusyngKite' }).then(marimba => this.setState({player:marimba}));
  }

  chooseBeschriftung(e) {
    this.setStateAndStorage({beschriftung: e.target.value});
  }

  computeFretTo() {
    const v = parseInt(this.state.fret_from, 10) + parseInt(this.state.fret_range, 10) -1;
    //console.log("huch",v);
    return v;
  }

  chooseRoot(e) {
    //console.log("chosenRoot:",e.target.value % 12);
    const newRoot = e.target.value % 12;
    //console.log(midiNames[newRoot][1]);
    const i = e.target.value;
    const j = 12 - (i - 48);
    this.setStateAndStorage({rootIndex:j,qzr:qz[e.target.value % 12]});
    //console.log("rootIndex:",j);
  }

  nextStepScalemodel(m) {
    return m.map((e) => {
      switch (e) {
        case 0:
          return 0;
        case 1:
          return 7;
        default:
          return e -1;
      }
    });
  }

  toggleSlideMode(checked) {
    this.setStateAndStorage({slideMode:checked});
  }

  toggleCreativeMode(checked) {
    this.setStateAndStorage({creativeMode:checked});
  }

  getCreativeChordColor(string, pitch) {
    //console.log("calling getCreativeChordColor with", string, pitch)
    if (this.state) {
      var k = `${string}_${pitch}`;
        const colorindex = this.state.creativeChordColorIndex[k]
        //console.log("colorindex of", k, ":", colorindex);
        if (colorindex !== undefined) {
          return COLORS[colorindex]
        } else {
          return '#666'
        }
    } else {
      return '#e88666'
    }
  }


  setCreativePitch(string,pitch,allowEdit) {
    const a = this.state.creativeMainChord.slice();
    var creativeChords;
    if (allowEdit) {

      if (a[string] == pitch) {
        a[string] = 0
      } else {
        //console.log(`settingCreativPitch at position ${string} to pitch ${a}`);
        a[string] = pitch;
      }
      creativeChords = this.setCreativeChordScale(a)
    } else {
      creativeChords = this.state.creativeChords
    }



    // Was erklingt beim Click?
    if (this.state.player) {
      /* Algorithmus wie folgt:
         creativeChords durchloopen
         Den Akkord (wenn es einen gibt), der zur übergebenen Saite/Ton-Kombi passt
         spielen
      */

      var player = this.state.player;
      var showString = this.state.showString;

      for (var cc of creativeChords) {
        if (cc[string] === pitch) {
          //console.log("cc:", cc);
          var cnt = 0;

          // ccl hält die Werte des Akkords,
          // den wir als Brechung hören wollen
          const ccl = [];

          // Wir machen eine Kopie von cc in ccl,
          // die keine "0"-Noten, also ungespielte enthält.
          // Das Arpeggio würde sonst unrund klingen (mit "Pausen")
          for (var i = 0; i < 6; i++) {
            const pitch = cc[i];
            if (cc[i] !== 0) {
              ccl.push([i,pitch])
            }
          }

          // Arpeggio von unten nach oben spielen
          ccl.reverse();

          player.stop();

          //
          var intervalId = setInterval(function() {
            // Sind wir am Ende der Akkordbrechung: interval löschen
            if (cnt === ccl.length) {
              clearInterval(intervalId)
            }

            // tpl heißt "to play", die aktuelle Note
            const tpl = ccl[cnt];

            // Am Anfang kann tpl noch undefined sein (wenn es keinen ccl[cnt] gibt)
            if (tpl !== undefined) {
              // Die Saite muss aktiv sein
              if (showString[tpl[0]] === true) {
                const pitch = tpl[1];
                player.play(pitch);
              }
            }
            cnt++;
          }, 18);
        }
      }
    }
  }

  setCreativeChordScale(a) {

    var creativeChords;
    const creativeChordColorIndex = {};


    creativeChords = [];

    creativeChords.push(a);

    console.log("main chord in setCreativeChordScale:", a);

    const creativeChordScalesMaterial = this.computeCreativeChordScale();
    const creativeChordScales = [];
    var creativeSubScales = [];
    //console.log('creativeChordScalesMaterial:', creativeChordScalesMaterial);

    // In creativeChordScalesMaterial stecken jetzt alle Noten, die wir für
    // die Akkorde brauchen
    // Jetzt geht es darum Slices zu bilden: alles rechts und später alles
    // links des "main" Chords

    for (var i = 0; i < 6; i++) {

      const localPitch = a[i];
      //console.log("localPitch:", localPitch);
      if (localPitch !== 0) {
        // den localPitch in der Notenliste finden
        for (var n of creativeChordScalesMaterial[i]) {
          if (localPitch === n.pitch) {
            const myIndex = creativeChordScalesMaterial[i].indexOf(n);
            creativeChordScales[i] = creativeChordScalesMaterial[i].slice(myIndex +1)
            creativeSubScales[i] = creativeChordScalesMaterial[i].slice(0, myIndex);
            //console.log("treffer:", n)
          }
        }
      } else {
        creativeChordScales[i] = [];
        creativeSubScales[i] = [];
      }
    }

    creativeSubScales = creativeSubScales.map((s) => s.reverse());

    /*
    console.log('creativeChordScalesMaterial nach:', creativeChordScalesMaterial);
    console.log('creativeChordScales nach:', creativeChordScales);
    console.log('creativeSubScales nach:', creativeSubScales);
    */



    // Jetzt haben wir die rechten Slices.
    // Erstmal die Länge des längsten ermitteln
    const laengen = creativeChordScales.map((s) => s.length);
    const maxLen = Math.max(...laengen)
    //console.log("laengstes Array:", maxLen);

    // jetzt iterieren wir "horizontal", der Saite entlang
    for (var i = 0; i < maxLen; i++) {
      const chordArray = []; // chordArray hält die Tonhöhen des aktuellen Akkords
      //const creativeChordColorIndex = {};
      // Jetzt über die Saiten (vertikal) iterieren
      for (var j = 0; j < 6; j++) {
        const targetNote = creativeChordScales[j][i];
        // nächste Note rausfischen
        // Ist unter den Indizes nichts zu finden, ist der Wert undefined
        if (targetNote !== undefined) {
          chordArray.push(targetNote.pitch); // Pitch in den Akkord aufnehmen
          creativeChordColorIndex[`${j}_${targetNote.pitch}`] = i % COLORS.length;
        } else {
          chordArray.push(0); // 0 pushen
          //creativeChordColorIndex[`${j}_${targetNote.pitch}`] = i % COLORS.length;
        }
      }
      creativeChords.push(chordArray); // ist der Akkord fertig, in die Akkordliste damit
    }
    //this.setState({creativeMainChord:a, creativeChords, creativeChordColorIndex});

    // Das gleiche mit den linken Slices.
    // Erstmal die Länge des längsten ermitteln
    const laengenSub = creativeSubScales.map((s) => s.length);
    const maxLenSub = Math.max(...laengenSub)
    //console.log("laengstes Array in creativeSubScales:", maxLen);

    // jetzt iterieren wir "horizontal", der Saite entlang
    for (var i = 0; i < maxLenSub; i++) {
      const chordArraySub = []; // chordArray hält die Tonhöhen des aktuellen Akkords
      //const creativeChordColorIndex = {};
      // Jetzt über die Saiten (vertikal) iterieren
      for (var j = 0; j < 6; j++) {
        const targetNote = creativeSubScales[j][i];
        // nächste Note rausfischen
        // Ist unter den Indizes nichts zu finden, ist der Wert undefined
        if (targetNote !== undefined) {
          chordArraySub.push(targetNote.pitch); // Pitch in den Akkord aufnehmen
          creativeChordColorIndex[`${j}_${targetNote.pitch}`] = COLORS.length - ((i % COLORS.length) + 1);
        } else {
          chordArraySub.push(0); // 0 pushen
          //creativeChordColorIndex[`${j}_${targetNote.pitch}`] = i % COLORS.length;
        }
      }
      creativeChords.push(chordArraySub); // ist der Akkord fertig, in die Akkordliste damit
    }
    this.setState({creativeMainChord:a, creativeChords, creativeChordColorIndex});

    return creativeChords;
  }

  render() {

    const finalScalemodel = this.computeFinalScalemodel();
    //console.log("finalScalemodel:",finalScalemodel);

    const scaleModelType = this.state.scale_model_current_key;
    //console.log(scaleModelType);
    const modeNamesLocal = modeNames[scaleModelType];

    /*
    console.log("modeNamesLocal:",modeNamesLocal);
    console.log("scaleStep:", this.state.scaleStep);
    */
    const scaleStepMode = modeNamesLocal[this.state.scaleStep];
    //console.log("scaleStepMode:",scaleStepMode);

    const modeRoot = midiNames[(12-this.state.rootIndex) + finalScalemodel.indexOf(1)][1];

		const block = modeNamesLocal.map((v, i) =>
			<div key={i} onClick={() => this.chooseStepByInt(i)}>
				<span style={{color:'#333',cursor:'pointer'}}>{(i+1).toString()}. Stufe: {modeNamesLocal[i]}</span>
			</div>
		);

    const instrumente = instrumentNames.map((v, i) =>
    <option
      value={v[0]}
      label={v[1]}
      >{v[1]}</option>
    )

    const stepOptions = [0,1,2,3,4,5,6].map((v) =>
      <option
        value={v}
        key={v}
        label={(v+1).toString() +
          ". Stufe: " +
          midiNames[(12- this.state.rootIndex) + this.computeTonicScalemodel().indexOf(v+1)][this.state.qzr] + " " +
          modeNamesLocal[v]}
      >{(v+1).toString() +
        ". Stufe: " +
        midiNames[(12- this.state.rootIndex) + this.computeTonicScalemodel().indexOf(v+1)][this.state.qzr] + " " +
        modeNamesLocal[v]}</option>
    );

    const beschriftungOptions =
      [<option
        value={"Namen"}
        key={"names"}
        label={"Notennamen"}
        >{"Notennamen"}</option>,
      <option
        value={"Stufe"}
        key={"step"}
        label={"Stufe"}
        >{"Stufe"}</option>,
      <option
        value={"Keine"}
        key={"none"}
        label={"Keine"}
        >{"Keine"}</option>]

    const fretsShown = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23].map((v) =>
      <option
        value={v}
        key={v}
        label={v}
        >{v}</option>
    );

		//console.log(block);

    const { classes } = this.props;

    return (
      <div className="App" style={{backgroundColor: '#282c34'}}>
        <div className="row">
          <div className="col" style={{textAlign:'center',height:'80px',paddingTop:'1em'}}>
            <h1 style={{color:'white'}}>Fretlogic</h1>
          </div>
        </div>
        <div className="row">
          <div className="col" style={{backgroundColor:'#ffc',width:'20%',padding:'0.5em'}}>
            <div style={{color:'#333', textAlign:'center'}}>Tonmaterial</div>
            <div style={{display:'inline'}}>
              <select
                onChange={(e) => this.chooseRoot(e)}
                id="selectRoot"
                style={{width:'80%'}}
                value={60 - this.state.rootIndex}>
                <option value="48" label="C">C</option>
                <option value="49" label="C#/Db">C#/Db</option>
                <option value="50" label="D">D</option>
                <option value="51" label="D#/Eb">D#/Eb</option>
                <option value="52" label="E">E</option>
                <option value="53" label="F">F</option>
                <option value="54" label="F#/Gb">F#/Gb</option>
                <option value="55" label="G">G</option>
                <option value="56" label="G#/Ab">G#/Ab</option>
                <option value="57" label="A">A</option>
                <option value="58" label="Bb">Bb</option>
                <option value="59" label="B">B</option>
              </select>
            </div>

            <div>
              <select
                onChange={(e) => this.chooseScaleModel(e)}
                id="selectScaleMode"
                style={{width:'80%'}}
                value={scaleModelType}>
                <option value="dur" label="Dur">Ionisch</option>
                <option value="mm" label="Melodisch Moll">Melodisch Moll</option>
                <option value="hm" label="Harmonisch Moll">Harmonisch Moll</option>
                <option value="hd" label="Harmonisch Dur">Harmonisch Dur</option>
              </select>
            </div>
            <div>
              <select
                onChange={(e) => this.chooseStep(e)} id="selectStep" value={this.state.scaleStep}
                style={{width:'80%', cursor:'default'}}
                >
                {stepOptions}
              </select>
            </div>
            <div> => {modeRoot + " " + scaleStepMode}</div>

          </div>

          <div className="col" style={{backgroundColor:'#ffc',padding:'0.5em',width:'20%'}}>
            {block}
          </div>

          <div className="col" style={{float:'left'}}>
            <img src={logo} className="App-logo-still" alt="logo" />
          </div>

          <div className="col" style={{backgroundColor:'#ffc',padding:'0.5em',paddingTop:'0.5em',width:'20%'}}>
            <div className="row" style={{color:'#333',marginLeft:'0.5em',textAlign:'left',width:'100%'}}>Stufen angezeigt</div>
            <div className="row" style={{cursor:'pointer',marginLeft:'0.5em'}}>
              <button
                onClick={(e) => this.toggleShowStep(0)}
                style={{float: 'left', backgroundColor: this.state.showStep[0] === true ? '#ff4100' : '#ccc' }}>1</button>
              <button
                onClick={(e) => this.toggleShowStep(1)}
                style={{float: 'left', backgroundColor: this.state.showStep[1] === true ? '#ff4100' : '#ccc' }}>2</button>
              <button
                onClick={(e) => this.toggleShowStep(2)}
                style={{float: 'left', backgroundColor: this.state.showStep[2] === true ? '#ff4100' : '#ccc' }}>3</button>
              <button
                onClick={(e) => this.toggleShowStep(3)}
                style={{float: 'left', backgroundColor: this.state.showStep[3] === true ? '#ff4100' : '#ccc' }}>4</button>
              <button
                onClick={(e) => this.toggleShowStep(4)}
                style={{float: 'left', backgroundColor: this.state.showStep[4] === true ? '#ff4100' : '#ccc' }}>5</button>
              <button
                onClick={(e) => this.toggleShowStep(5)}
                style={{float: 'left', backgroundColor: this.state.showStep[5] === true ? '#ff4100' : '#ccc' }}>6</button>
              <button
                onClick={(e) => this.toggleShowStep(6)}
                style={{float: 'left', backgroundColor: this.state.showStep[6] === true ? '#ff4100' : '#ccc' }}>7</button>
            </div>

            <div className="row" style={{color:'#333', textAlign:'left',width:'100%',marginLeft:'0.5em'}}>Saiten aktiv</div>
            <div className="row" style={{marginLeft:'0.5em'}}>
              <button
                onClick={(e) => this.toggleShowString(0)}
                style={{float: 'left', backgroundColor: this.state.showString[0] === true ? '#ff4100' : '#ccc' }}>1</button>
              <button
                onClick={(e) => this.toggleShowString(1)}
                style={{float: 'left', backgroundColor: this.state.showString[1] === true ? '#ff4100' : '#ccc' }}>2</button>
              <button
                onClick={(e) => this.toggleShowString(2)}
                style={{float: 'left', backgroundColor: this.state.showString[2] === true ? '#ff4100' : '#ccc' }}>3</button>
              <button
                onClick={(e) => this.toggleShowString(3)}
                style={{float: 'left', backgroundColor: this.state.showString[3] === true ? '#ff4100' : '#ccc' }}>4</button>
              <button
                onClick={(e) => this.toggleShowString(4)}
                style={{float: 'left', backgroundColor: this.state.showString[4] === true ? '#ff4100' : '#ccc' }}>5</button>
              <button
                onClick={(e) => this.toggleShowString(5)}
                style={{float: 'left', backgroundColor: this.state.showString[5] === true ? '#ff4100' : '#ccc' }}>6</button>
            </div>
          </div>

          <div className="col" style={{backgroundColor:'#ffc',padding:'0.5em',width:'20%'}}>
            <div className={classes.innerrow} style={{color:'#333', textAlign:'left',width:'100%'}}>
              <div style={{display: 'inline'}}>Hervorgehoben von Bund &nbsp;</div>
            <select
              onChange={(e) => this.chooseFretFrom(e)}
              value={this.state.fret_from}
              style={{textAlign:'left', width:'15%'}}>
              {fretsShown}
            </select>
            </div>
            <div className={classes.innerrow} style={{color:'#333', textAlign:'left',width:'100%'}}>Wieviele? &nbsp;
              <select onChange={(e) => this.chooseFretRange(e)} value={this.state.fret_range}>
                {fretsShown}
              </select>
            </div>
            <div className={classes.innerrow} style={{color:'#333', textAlign:'left',width:'100%'}}>Sound: &nbsp;
              <select onChange={(e) => this.chooseInstrument(e)} value={this.state.instrument}>
                {instrumente}
              </select>
            </div>
            <div className={classes.innerrow} style={{color:'#333', textAlign:'left',width:'100%'}}>Beschriftung: &nbsp;
              <select onChange={(e) => this.chooseBeschriftung(e)} value={this.state.beschriftung}>
                {beschriftungOptions}
              </select>
            </div>
            <div className={classes.innerrow} style={{color:'#333', textAlign:'left',width:'100%'}}>
              <label>
                <span>Slide mode:&nbsp;</span>
                <Switch
                  height={20}
                  onChange={this.toggleSlideMode} checked={this.state.slideMode} />
              </label>
            </div>
            <div className={classes.innerrow} style={{color:'#333', textAlign:'left',width:'100%'}}>
              <label>
                <span>Creative mode:&nbsp;</span>
                <Switch
                  height={20}
                  onChange={this.toggleCreativeMode} checked={this.state.creativeMode} />
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col" style={{backgroundColor:'#300'}}>
            <Fretboard
              qzr={this.state.qzr}
              dir={'ltr'}
              beschriftung={this.state.beschriftung}
              soundPlayer={this.state.player}
              fretFrom={this.state.fret_from}
              fretTo={this.computeFretTo()}
              scaleModelType={this.state.scale_model_current_key} 
              scaleModel={finalScalemodel}
              showStep={this.state.showStep}
              showString={this.state.showString}
              slideMode={this.state.slideMode}
              creativeMode={this.state.creativeMode}
              createPitchFunction={this.setCreativePitch}
              creativeMainChord={this.state.creativeMainChord}
              creativeChordColorFunction={this.getCreativeChordColor}
              rootIndex={this.state.rootIndex}/>
            {/*}<Fretboard scaleModel={this.state.scale_model}/>*/}
          </div>
        </div>
        <div className="row" style={{backgroundColor:'#fff', height:'200px'}}>
          <Notation/>
        </div>
        <div className="row">
          <div className="col" style={{textAlign:'left',height:'800px',paddingTop:'1em',marginLeft:'1em'}}>
            <Link style={{color:'white'}} to="/impressum">Impressum</Link>
          </div>
        </div>
      </div>
    );
  }
}

FretboardLayer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);
