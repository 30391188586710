export const midiNames = [["C", "C"],["Db", "C#"],["D", "D"],["Eb", "D#"],["E", "E"], ["F", "F"], ["Gb", "F#"], ["G", "G"], ["Ab", "G#"], ["A", "A"], ["Bb", "A#"], ["B", "B"], ["C", "C"], ["Db", "C#"], ["D", "D"], ["Eb", "D#"], ["E", "E"], ["F", "F"], ["Gb", "F#"], ["G", "G"], ["Ab", "G#"], ["A", "A"], ["Bb", "A#"], ["B", "B"], ["C", "C"], ["Db", "C#"], ["D", "D"], ["Eb", "D#"], ["E", "E"], ["F", "F"], ["Gb", "F#"], ["G", "G"], ["Ab", "G#"], ["A", "A"], ["Bb", "A#"], ["B", "B"], ["C", "C"], ["Db", "C#"], ["D", "D"], ["Eb", "D#"], ["E", "E"], ["F", "F"], ["Gb", "F#"], ["G", "G"], ["Ab", "G#"], ["A", "A"], ["Bb", "A#"], ["B", "B"], ["C", "C"], ["Db", "C#"], ["D", "D"], ["Eb", "D#"], ["E", "E"], ["F", "F"], ["Gb", "F#"], ["G", "G"], ["Ab", "G#"], ["A", "A"], ["Bb", "A#"], ["B", "B"], ["C", "C"], ["Db", "C#"], ["D", "D"], ["Eb", "D#"], ["E", "E"], ["F", "F"], ["Gb", "F#"], ["G", "G"], ["Ab", "G#"], ["A", "A"], ["Bb", "A#"], ["B", "B"], ["C", "C"], ["Db", "C#"], ["D", "D"], ["Eb", "D#"], ["E", "E"], ["F", "F"], ["Gb", "F#"], ["G", "G"], ["Ab", "G#"], ["A", "A"], ["Bb", "A#"], ["B", "B"], ["C", "C"], ["Db", "C#"], ["D", "D"], ["Eb", "D#"], ["E", "E"], ["F", "F"], ["Gb", "F#"], ["G", "G"], ["Ab", "G#"], ["A", "A"], ["Bb", "A#"], ["B", "B"], ["C", "C"], ["Db", "C#"], ["D", "D"], ["Eb", "D#"], ["E", "E"], ["F", "F"], ["Gb", "F#"], ["G", "G"], ["Ab", "G#"], ["A", "A"], ["Bb", "A#"], ["B", "B"], ["C", "C"], ["Db", "C#"], ["D", "D"], ["Eb", "D#"], ["E", "E"], ["F", "F"], ["Gb", "F#"], ["G", "G"], ["Ab", "G#"], ["A", "A"], ["Bb", "A#"], ["B", "B"], ["C", "C"], ["Db", "C#"], ["D", "D"], ["Eb", "D#"], ["E", "E"], ["F", "F"], ["Gb", "F#"], ["G", "G"]]

export const modeNames = {
	dur: ["ionisch","dorisch","phrygisch","lydisch","mixolydisch","aeolisch","lokrisch"],
	mm: ["ionisch b3","dorisch b2","lydisch #5","lydisch b7","mixolydisch b6","äolisch b5","superlokrisch (alteriert)"],
	hm: ["äolisch maj7","lokrisch maj6","ionisch #5","dorisch #4","phrygisch maj3","lydisch #2","mixolydisch #1"],
	hd: ["hd1","hd2","hd3","hd4","hd5","hd6","hd7"]
}

// 0 ist im b-Bereich, 1 ist im #-Bereich
export const qz = {
	0: 1,
	1: 0,
	2: 1,
	3: 0,
	4: 1,
	5: 0,
	6: 1,
	7: 1,
	8: 0,
	9: 1,
	10: 0,
	11: 1
}

export const instrumentNames = [
	["acoustic_guitar_nylon", "Klassische Gitarre"],
	["acoustic_guitar_steel", "Stahlsaitengitarre"],
	['electric_guitar_clean', "E-Gitarre (Chorus)"],
  ['electric_guitar_jazz', "Jazzgitarre"],
	["vibraphone", "Vibraphon"],
	["acoustic_grand_piano", "Klavier"]
]
