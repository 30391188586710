import React, { Component } from 'react';
import { midiNames } from '../constants';
//import Sound from 'react-sound';

class Dot extends Component {

	constructor(props) {
		super(props);
		this.state = {
			diameter: 28
		}
		this.computeOpacity = this.computeOpacity.bind(this);
		this.computeStroke = this.computeStroke.bind(this);

		//console.log("props of dot:", this.props);
	}

	handleMouseDown(e) {
		if (this.props.player) {
			if (this.props.creativeMode === false)
			this.props.player.play(this.props.pitch)
		}

		if (this.props.creativeMode === true) {
			//console.log("calling createPitchFunction on dot with props", this.props.string, this.props.pitch, e.altKey);

			this.props.createPitchFunction(this.props.string, this.props.pitch, e.altKey);
		}
	}

	computeOpacity() {
		//return 0.4;

		var o;

		if (this.props.active) {
			o = 1.0;
		} else {
			o = 0.4;
		}

		if ([2,4,6].indexOf(this.props.step) > -1) {
			o = 1.0;
			if (!this.props.active) {
				o = 0.4
			}
		}

		// in 'creative mode' we dim the strings that are inactive
		if (this.props.creativeMode) {
			if (this.props.creativeMainChord[this.props.string] === 0) {
				o = 0.4
			}
		}
		return o;
	}

	computeStrokeOpacity() {
		if (this.props.active) {
			return 0.8;
		} else {
			return 0;
		}
	}

	computeStroke() {
		var stroke;

		switch(this.props.step) {
			case 1:
				stroke = "white"
				break;
			default:
				stroke = "transparent"
		}
		return stroke;
	}

  render() {

		const d = `M${this.props.x},${this.props.y-this.state.diameter/2.0}c0.173,0 0.146,26 0,26c-7.175,0 -13,-5.825 -13,-13c0,-7.175 5.825,-13 13,-13Z`;

		var fill;



		switch(this.props.step) {
			case 1:
				fill = "#ffBA00";
				break;
			case 3:
				fill = "#ff8200";
				break;
			case 5:
				fill = "#ff4100";
				break;
			case 7:
				fill = "#900";
				break;
			default:
				fill = "#666";
		}

		if (this.props.creativeMode === true) {
			fill = '#666';
			const creativeChordPitch = this.props.creativeMainChord[this.props.string];
			//console.log("cmc:", creativeChordPitch, creativeChordPitch === this.props.pitch)

			if (creativeChordPitch === this.props.pitch) {
				fill = '#e88666';
			} else {
				fill = this.props.creativeChordColorFunction(this.props.string,this.props.pitch);
			}
		}

		const noteName = midiNames[this.props.pitch][this.props.qzr]
		const step = this.props.step
		var anzeigeWert = "";
		switch (this.props.beschriftung) {
			case "Namen":
				anzeigeWert = noteName;
				break;
			case "Stufe":
				anzeigeWert = step.toString();
				break;
			case "Keine":
				anzeigeWert = "";
				break;
			default:
				anzeigeWert = "";
		}

    return (
			<g
				onMouseDown={(e) => {
					//console.log(this.props.string);
					this.handleMouseDown(e);
				}}
				onMouseEnter={(e) => {
					if (this.props.slideMode) {
						this.props.player.play(this.props.pitch)
					}
				}}
				>
			<circle
				cx={this.props.x}
				cy={this.props.y}
				r="14"
				stroke={this.computeStroke()}
				strokeOpacity={this.computeStrokeOpacity()}
				strokeWidth="3"
				fillOpacity={this.computeOpacity()}
				style={{"fill":fill}}>
				<animate
					attributeType="CSS"
					attributeName="fill"
					from={fill}
					to="#FFFFFF"
					dur="0.1s"
					values={`${fill} #fff ${fill}`}
					begin="click"
					repeatCount="1" />
			</circle>
			{Math.random() > 1.5 &&
				<path d={d} style={{"fill":"fill"}}/>
			}
			<text
				textAnchor="middle"
				x={this.props.x}
				y={this.props.y + 5}
				style={{fontSize:"14px",cursor:"default"}}
				fillOpacity={this.computeOpacity()}
				fill="white">{anzeigeWert}</text>

			</g>
    );
  }
}

export default Dot;
