import React, { Component } from 'react';
import Dot from './dot';
//import { modeNames } from '../constants';

const leftVersatz = 20;

class Fretboard extends Component {

	constructor(props) {
		super(props);
		this.state = {
			stringMarginTop: 15,
			stringAbstand: 30,
			fretboardLength: 1500,
			fretboardHeight: 300,
			circleDiameter: 26
		}
		this.fretpos = this.fretpos.bind(this);
	}

	dotpos(i) {
	    if (i > 0) {
	        var lowerfret = this.fretpos(this.state.fretboardLength, i-1);
	        var higherfret = this.fretpos(this.state.fretboardLength, i);
					if (this.props.dir === 'ltr') {
	        	return (higherfret + lowerfret)/2.0;
					} else {
						return this.state.fretboardLength - ((higherfret + lowerfret)/2.0 + 540);
					}
	    } else {
				if (this.props.dir === 'rtl') {
					return this.state.fretboardLength - 540;
				}
	        return leftVersatz;
	    }
	}

	fretpos(l,i) {
	    return l * (1 - Math.pow(2,(-i/12.0))) + leftVersatz;
	    }

	drawdot(stringnum, fretnum, pos) {
	  //var dotposition = this.dotpos(fretnum);

	  //var alpha = 0.5;
	  //var galpha = 0.1;

	  //var lightarray = new Array(1,0.8,0.4,1);

	  //var thirdarray = lightarray;
	  //var fiftharray = lightarray;
	  //var seventharray = lightarray;

	  var stringposition = (stringnum -1) * (this.state.fretboardHeight/5.0) + this.state.circleDiameter/2.0;
	  switch(pos)
		  {
		  case 1:
		      //set_source_rgba(new Array(1,0,0,1));
		      break;
		  case 3:
		      //set_source_rgba(thirdarray);
		      break;
		  case 5:
		      //set_source_rgba(fiftharray);
		      break;
		  case 7:
		      //set_source_rgba(seventharray);
		      break;
		  default:
				;
		      //t_source_rgba(new Array(0.8,0.8,0.8,1));
		  }
	  //ellipse(dotposition-this.state.circleDiameter/2.0,stringposition,this.state.circleDiameter,this.state.circleDiameter);
	  //fill();
	  //set_source_rgba(new Array(0,0,0,1));
	  //stroke();

		}

  render() {

		var scaleModel = this.props.scaleModel;
		while (scaleModel.length < 48) {
			scaleModel = scaleModel.concat(scaleModel);
		} // Liste 'extrudieren', damit sie über die Oktaven reicht

		/*
		const e1_list = scaleModel.slice(16); // für die E-Saite(n) verschieben wir unsere Liste um 4 Schritte (c#, d, d#, e)
		const b2_list = scaleModel.slice(11); // für die H-Saite um 11
		const g3_list = scaleModel.slice(19);
		const d4_list = scaleModel.slice(14);
		const a5_list = scaleModel.slice(9);
		*/
		
		/*
		console.log(e1_list);
		console.log(b2_list);
		console.log(g3_list);
		console.log(d4_list);
		console.log(a5_list);
		*/

		const saiten = [0,1,2,3,4,5].map((i) =>
			<path
				id='line'
				strokeWidth={(1+i*0.15).toString()}
				fill='none' stroke='white'
				key={i}
				d={`M${leftVersatz} ${this.state.stringMarginTop + (i*this.state.stringAbstand)} h 1000`}
				/>)

		const buende = [...Array(24).keys()].map((i) =>
		<path
			id='line'
			strokeWidth='1'
			fill='none' stroke='white'
			key={'bund_' + i.toString()}
			d={`M${this.fretpos(this.state.fretboardLength, i)} ${this.state.stringMarginTop} v 150`}
			/>
		)



		const tuning = [64, 59, 55, 50, 45, 40]

		const dots = [0,1,2,3,4,5].map((j) =>
			[	...Array(24).keys()].map((i) => {
				//console.log(j+1, i);
				const scale_offset = tuning[j] % 12;

				/*
				if (i === 0 && j === 0) {
					console.log("fretboard -> scaleModel:", scaleModel);
					console.log("fretboard -> scale_offset:", scale_offset);
					console.log("fretboard -> rootIndex:", this.props.rootIndex);
				}
				*/

				var compare_SCALE = scaleModel.slice(scale_offset).slice(this.props.rootIndex);
				//compare_SCALE = [0,0].concat(compare_SCALE);

				const activeFret = (i >= this.props.fretFrom) && (i <= this.props.fretTo);
				const step = compare_SCALE[i];

				/*
				if (i === 0 && j === 0) {
					console.log("fretboard -> compare_SCALE:", compare_SCALE);
				}
				*/

				if (step !== 0 && this.props.showString[j] && this.props.showStep[step - 1] === true ) {
					return <Dot
										slideMode={this.props.slideMode}
										player={this.props.soundPlayer}
										active={activeFret}
										x={this.dotpos(i)}
										y={15 + (j * 30)}
										string={j}
										pitch={tuning[j] + i}
										qzr={this.props.qzr}
										key={tuning[j]+i}
										createPitchFunction={this.props.createPitchFunction}
										creativeMainChord={this.props.creativeMainChord}
										beschriftung={this.props.beschriftung}
										creativeMode={this.props.creativeMode}
										creativeChordColorFunction={this.props.creativeChordColorFunction}
										step={compare_SCALE[i]}/>
				} else {
					;
				}
			}
			)
		)

		const bundbeschriftung = [0,3,5,7,9,12,15,17].map((i) => {
			const y = 6.6 * this.state.stringAbstand;
			const hfx = this.fretpos(this.state.fretboardLength, i);
			const lfx = this.fretpos(this.state.fretboardLength, i-1);
			var x = (hfx+lfx)/2.0;
			if (i === 0) {
				x = this.fretpos(this.state.fretboardLength, 0);
			}
			return (
				<text x={x} y={y}
					fontFamily="sans-serif"
      		fontSize="16px"
      		textAnchor="middle"
      		fill="grey">{i}</text>
			)
		})


    return (
      <div className="Fretboard">
        <svg
					style={{marginTop: '20'}}
					height="300"
					transform="scale(1.3) translate(0 40)"

					width="1000">
					{saiten}
					{buende}
					{dots}
					{bundbeschriftung}
				</svg>
      </div>
    );
  }
}

export default Fretboard;
